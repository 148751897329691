import React from 'react';
import {Container, Nav, Navbar, NavDropdown, Row, Col} from 'react-bootstrap'
import Efficiency from './development/Efficiency';
import Waste from './development/Waste';
import MyWaste from './development/MyWaste';
import Industries from './development/Industries';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import office from './img/office.jpg';
class DevelopmentPage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return ([

            <Row  key="waste">       
                <Col style={{display: 'flex', justifyContent: 'center'}} md="6" sm="12" >
                <Waste />
                </Col>
                <Col  style={{display: 'flex', justifyContent: 'center'}} md="6" sm="12">
                <MyWaste />
                </Col>
            </Row>,

            <Row  key="key_efficiency">       
                <Col  style={{display: 'flex', justifyContent: 'center'}} md="6" sm="12">
                <Efficiency />
                </Col>
                <Col  style={{display: 'flex', justifyContent: 'center'}} md="6" sm="12">
                <Industries key="key_industries" />
                </Col>
            </Row>

        ]);
    }
}

export default DevelopmentPage;

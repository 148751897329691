import React from 'react';
import './../../node_modules/react-vis/dist/style.css';
import {XYPlot, LineSeries, VerticalGridLines, HorizontalGridLines, XAxis, YAxis} from 'react-vis';
import Card from './../components/Card';


class Efficiency extends React.Component {

    render() {
        const data = [
            {x: 0, y: 1},
            {x: 3, y: 2},
            {x: 6, y: 3},
            {x: 9, y: 4},
            {x: 12, y: 5},
            {x: 15, y: 6},
            {x: 18, y: 7},
            {x: 21, y: 8},
            {x: 24, y: 9},
            {x: 27, y: 10}
        ];
        const dataAVGMax = [
            {x: 0, y: 5},
            {x: 25, y: 5}
        ];
        const dataAVGMin = [
            {x: 0, y:8},
            {x: 25, y: 8}
        ];
        
        var chart =   <XYPlot height={300} width= {300}>
                            <VerticalGridLines />
                            <HorizontalGridLines />
                            <XAxis />
                            <YAxis />
                            <LineSeries data={data} />
                            <LineSeries data={dataAVGMin} />        
                            <LineSeries data={dataAVGMax} />        
                        </XYPlot>
        
        
        return <Card 
            keyPrefix="profit"
            header="MEGTÉRÜLÉS" 
            content="Tapasztalataink szerint a befektetések átlagban 10 - 20 hónap alatt megtérülnek.
            Volt olyan cég ahol a szoftver révén annyival csökkent az adminisztrációval töltött idő, hogy a cég új tevékenységet tudott felvenni a régi mellé.
            " 
            
            visualizaiton={chart} 
            />
    }
}

export default Efficiency;



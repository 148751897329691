import React from 'react';
import './../../node_modules/react-vis/dist/style.css';
import Card from './../components/Card';
import {Row, Col} from 'react-bootstrap'
import ExperienceImage from './../img/husz.svg';
import maintenance from './../img/maintenance.svg';
import technology from './../img/technology.svg';


class Experience extends React.Component {

    render() {
        var content = [];
        content.push(<li key="li_1">Az egyedileg fejlesztett rendszereink révén olyan megoldásokat hozunk létre, amivel ügyfeleink hatékonyan vehetik fel a versenyt a többi piaci szereplővel.</li>);
        content.push(<li key="li_2">Minden ügyfelünk egyedi, ezért a rendszer gerincét a különleges, csak az adott vállalkozásra jellemző folyamatokra építjük fel.</li>);
        content.push(<li key="li_3">A cél elérése érdekében ötvözzük a legkorszerűbb technológiákat az évtizedes bevállt módszerekkel.</li>);
        content.push(<li key="li_4">Fejlesztéseink 20 év vállalatirányítási területen szerzett tapasztalatra és közel 100 projektben való részvételre épülnek.</li>);
        var wrapper = <div style={{margin:0,padding:0,textAlign: 'left', minHeight: '100%', lineHeight: 2}}>
            <br/>
            <br/>
            <ul>{content}</ul>
           
        </div>;

        var caseStudy = [];
        caseStudy.push(<dd key="cs_1"><b>Esettanulmány:</b></dd>);
        caseStudy.push(<dd key="cs_2"></dd>);
        caseStudy.push(<dd key="cs_3">A szoftver megrendelője egyedi vagy kis sorozatban gyártott alkatrészek előállításával foglalkozik.</dd>);
        caseStudy.push(<dd key="cs_4">A cég üzleti folyamata a következő volt.:</dd>);
        caseStudy.push(<li key="cs_5">A cég a vevő kérése alapján elkészíti az alkatrész gyártásához szükséges terveket. </li>);
        caseStudy.push(<li key="cs_6">A tervek alapján kiszámolják a gyártás költségét az aktuális anyagáraknak és a gyártás időigényének megfelelően. </li>);
        caseStudy.push(<li key="cs_7">Készítenek egy ajánlatot a vevő részére. </li>);
        caseStudy.push(<li key="cs_8">A vevő jóváhagyja az árakat. </li>);
        caseStudy.push(<li key="cs_9">A terveket eljuttatják a műhelybe. </li>);
        caseStudy.push(<li key="cs_10">Ott legyártják a megrendelt alkatrészeket. </li>);
        caseStudy.push(<li key="cs_11">Értesítik a vevőt hogy a megrendelés elkészült. </li>);
        caseStudy.push(<dd key="cs_12"></dd>);
        caseStudy.push(<dd style={{borderBottom:'dotted 1px grey'}}key="cs_13"></dd>);
        
        
        caseStudy.push(<li key="cs_14"><b>Mit sikerült elérni az egyedi szoftver révén?</b></li>);
        caseStudy.push(<dd key="cs_15"></dd>);
        caseStudy.push(<li key="cs_16">Az ügyfél megrendelései bekerülnek a rendszerbe, ezáltal <b>visszakereshetik a korábbi terveket</b> ha újbóli gyártásra van szükség. Nem kell még egyszer megtervezni. <b>Nincs felesleges tervezési idő/költség.</b></li>);
         caseStudy.push(<dd key="cs_15"></dd>);
        caseStudy.push(<li key="cs_17">A tervezés során előállt dokumentumokból kinyerhetők a számításokhoz szükséges adatok, igy <b>az árkalkuláció a terv létrehozásával egy időben automatikusan létrejön</b>. Az árkalkuláció készítése a szoftver bevezetése előtt hosszas folyamat volt. Ezt sikerült néhány kattintásra redukálni. </li>);
         caseStudy.push(<dd key="cs_15"></dd>);
        caseStudy.push(<li key="cs_18">A szoftvernek köszönhetően <b>egy gombnyomásra elkészül az ajánlat és egy gombnyomásra elküldik a megrendelőnek.</b> </li>);
         caseStudy.push(<dd key="cs_15"></dd>);
        caseStudy.push(<li key="cs_19">Az árajánlat jóváhagyása után <b>a gyártást egy mozdulattal be lehet ütemezni</b> tetszőleges időpontra. </li>);
         caseStudy.push(<dd key="cs_15"></dd>);
        caseStudy.push(<li key="cs_20">A műhelyben <b>az ütemezésnek megfelelő sorrendben a gépeknél megjelennek az munkák</b>. A gyártás végeztével a műhely egy gombnyomással jelzi, hogy elkészültek  az alkatrészek. </li>);
        caseStudy.push(<dd key="cs_21"></dd>);
        caseStudy.push(<dd key="cs_22"></dd>);
        caseStudy.push(<li key="cs_23">Az ütemező felület egy idővonalon <b>valós időben mutatja, hogyan haladnak a munkák</b>. A felület révén <b>pontos választ tudnak adni a megrendelőnek</b> arra a kérdésére, hogy <b>mikor lesz kész a megrendelése</b>. A munka végeztével azonnal értesíthető a vevő. <b>Az ütemező felület segít a munkabeosztás tervezésében is</b>.  </li>);


        var caseStudyWrapper = <div style={{textAlign: 'left', minHeight: '100%', lineHeight: 1.4, marginLeft:40}}>
            
            <dl>{caseStudy}</dl>
        </div>;


        



        return <div>
        <Row>
            <Col  lg={6} xl={8} >
            <Card 
                keyPrefix="solutions"
                header="INTELLIGENS MEGOLDÁSOK" 
                content={wrapper}
                />
                 
                
            </Col>  
             <Col className="d-flex align-items-center justify-content-center"  lg={6} xl={4} style={{textAlign:'center'}}>
                <img style={{maxHeight:300}} src={technology}/>
            </Col>
        </Row>
        <Row>
            <Col lg={6} xl={8}>
            <Card 
                keyPrefix="caseStudy"
                header="ESETTANULMÁNY" 
                content={caseStudyWrapper}
                />
            </Col>
            <Col className="d-flex align-items-center justify-content-center"  lg={6} xl={4} style={{textAlign:'center'}}>
                <img  style={{maxHeight:300}} src={maintenance}/>
            </Col>
        </Row>
    </div>
    }
}

export default Experience;



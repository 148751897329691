import React from 'react';
import './../../node_modules/react-vis/dist/style.css';
import Card from './../components/Card';

import {RadialChart} from 'react-vis';

class Waste extends React.Component {

    render() {
        const data = [{angle: 1, label: 'TERMÉKRE FORDÍTVA'}, {angle: 3, label: 'EGYÉB KIADÁSOK'}];
        var chart = <RadialChart
            data={data}
            width={300}
            height={300} 
            showLabels={true}
            />
        return <Card 
            keyPrefix="waste"
            header="ÁTLAG KIVITELEZÉS" 
            content="A befektetett tőke jelentős részét felemésztik a szoftverfejlesztő cégek járulékos költségei. 
            (Iroda és gépjárművek fenntartása, személyzet...)" 
            visualizaiton={chart} 
            />
    }
}

export default Waste;



import React from 'react';
import './../../node_modules/react-vis/dist/style.css';
import Card from './../components/Card';

import {RadialChart} from 'react-vis';

class MyWaste extends React.Component {

    render() {
        const data = [{angle: 29, label: 'TERMÉKRE FORDÍTVA'}, {angle: 1, label: 'EGYÉB KIADÁSOK'}];
        var chart = <RadialChart
            data={data}
            width={300}
            height={300} 
            showLabels={true}
            />
        return <Card 
            keyPrefix="mywaste"
            header="ÁLTALUNK KIVITELEZETT" 
            content="A befektetett tőke a termékben összpontosul. 
            Ez azt jelenti, hogy olcsóbb lesz termék vagy gazdagabb funkcionalitást szállítunk ugyanazért az összegért." 
            visualizaiton={chart} 
            />
    }
}

export default MyWaste;



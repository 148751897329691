import React from 'react';
import './../../node_modules/react-vis/dist/style.css';
import WideCard from './../components/WideCard';

import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/base.css';

import '@inovua/reactdatagrid-community/theme/default-dark.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';

import ReactDataGrid from '@inovua/reactdatagrid-community';



class Industries extends React.Component {

    constructor(props) {
        super(props);
        this.state = {dataSource: []}
    }

    dataLoader = function ( { skip, limit, sortInfo, groupBy, filterValue }){
        var data = [
            {id: 1, name: 'Egészségügy'},
            {id: 2, name: 'Energetika'},
            {id: 3, name: 'Faipar'},
            {id: 4, name: 'Fémipar'},
            {id: 5, name: 'Gyógyszeripar'},
            {id: 6, name: 'Kereskedelem'},
            {id: 7, name: 'Pénzügy'},
            {id: 8, name: 'Szórakoztatóipar'},
            {id: 9, name: 'Távközlés'},
            {id: 10, name: 'Vendéglátás'},
            {id: 11, name: '...'}
        ];
        return Promise.resolve({data, count: parseInt(data.length), skip: 0});
    }

    render() {

        const columns = [
            {name: 'id', header: 'Id', defaultVisible: false, minWidth: 100, type: 'number'},
            {name: 'name', header: 'KORÁBBI MŰKÖDÉSI TERÜLETEINK:', defaultFlex: 1}
        ];


        var grid = <ReactDataGrid 
            idProperty="autok_id" 
            columns={columns}
            dataSource={this.dataLoader.bind(this)}
            enableSelection={false}
            theme={'default-light'}
            defaultLimit={50}
            pagination={false}
            style={{minHeight: 300, minWidth: '100% !important'}}
            />;
        return <WideCard 
            keyPrefix="industries" 
            header="IPARÁGAK" 
            visualizaiton={grid} 
            />
    }
}

export default Industries;


